*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Patrick Hand", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #49b7d8;
}

h1,
h2,
p {
  color: #fff;
  font-weight: normal;
}

h1 {
  font-size: 49px;
}

h2,
p {
  font-size: 30px;
}
