.button {
  font-family: inherit;
  font-size: 28px;
  color: #f43800;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #fae732;
  border: 1px solid #a5a148;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px 20px;
  transition: all 0.25s ease;
  cursor: pointer;
}

.button span {
  margin-block-start: 0;
  margin-block-end: 8px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.button:hover:not(:disabled) {
  transform: scale(1.2);
}

.button:disabled {
  cursor: default;
}
