.Game {
  max-width: 800px;
  min-height: 650px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.Game-header {
  display: flex;
  justify-content: space-between;
}
