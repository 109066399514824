.lives-remaining__num {
  display: inline-block;
  font-size: 28px;
  color: #f43800;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #fae732;
  border: 1px solid #a5a148;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px 15px;
  margin-right: 10px;
}

.lives-remaining__text {
  color: #fff;
  font-size: 30px;
}
